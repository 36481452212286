<template>
  <div class="network">
    <div class="network__type">
      <esmp-radio-group
        :items="typeItems"
        v-model.number="type"
      />
    </div>
    <div class="network__inputs">
      <esmp-input
        v-if="type === 1 || type === 2"
        v-model="ipAddress"
        class="network__input"
        label="0.0.0.0"
        @input="setIpAddress('ipAddress', ipAddress)"
      />
      <esmp-select
        v-if="type === 2"
        placeholder="Выберите маску подсети"
        v-model="netMask"
        class="network__select"
        @on-change="setMask"
      >
        <esmp-select-option
          v-for="item in items"
          :key="item.id"
          :value="item.name"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
      <esmp-input
        v-if="type === 3"
        v-model="startIpAddress"
        class="network__input"
        label="0.0.0.0"
        @input="setIpAddress('startIpAddress', startIpAddress)"
      />
      <span v-if="type === 3" class="network__separator">
        -
      </span>
      <esmp-input
        v-if="type === 3"
        v-model="endIpAddress"
        class="network__input"
        label="0.0.0.0"
        @input="setIpAddress('endIpAddress', endIpAddress)"
      />
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import { TYPES, SOURCE_SYSTEM } from '@/constants/network';

export default {
  name: 'Network',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: () => [], // маски подсетей
    },
  },
  data() {
    return {
      ipAddress: '', // если тип = host или network
      netMask: '', // если тип = network
      startIpAddress: '', // если тип = range
      endIpAddress: '', // если тип = range
      valueToSend: {
        sourceSystem: SOURCE_SYSTEM,
        type: 'host',
        ipAddress: '',
      },
      type: 1,
      typeItems: TYPES,
    };
  },
  methods: {
    setMask() {
      this.valueToSend = {
        ...this.valueToSend,
        netMask: this.netMask,
      };
    },
    setIpAddress(key, value) {
      this.valueToSend = {
        ...this.valueToSend,
        [key]: value,
      };
    },
    setValue(value) {
      if (value) {
        this.valueToSend = value;

        this.type = this.typeItems.find(
          (typeItem) => typeItem.type === value.type,
        )?.value;

        if (value.ipAddress) this.ipAddress = value.ipAddress;
        if (value.netMask) this.netMask = value.netMask;
        if (value.startIpAddress) this.startIpAddress = value.startIpAddress;
        if (value.endIpAddress) this.endIpAddress = value.endIpAddress;
      }
    },
  },
  created() {
    this.setValue(this.value);
  },
  watch: {
    valueToSend: {
      handler(val) {
        this.$emit('change', val);
      },
      deep: true,
    },
    type(val) {
      switch (val) {
      case 1:
        this.valueToSend = {
          sourceSystem: SOURCE_SYSTEM,
          type: 'host',
          ipAddress: this.ipAddress,
        };
        break;
      case 2:
        this.valueToSend = {
          sourceSystem: SOURCE_SYSTEM,
          type: 'network',
          ipAddress: this.ipAddress,
          netMask: this.netMask,
        };
        break;
      case 3:
        this.valueToSend = {
          sourceSystem: SOURCE_SYSTEM,
          type: 'range',
          startIpAddress: this.startIpAddress,
          endIpAddress: this.endIpAddress,
        };
        break;
      case 4:
        this.valueToSend = {
          sourceSystem: SOURCE_SYSTEM,
          type: 'internet',
        };
        break;
      default:
        this.valueToSend = {
          sourceSystem: SOURCE_SYSTEM,
          type: 'host',
          ipAddress: '',
        };
      }
    },
    value(val, oldVal) {
      if (!isEqual(val, oldVal)) {
        this.setVal(val);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.network {
  display: flex;
  flex-direction: row;
  @include for-size(phone-portrait-down) {
    flex-direction: column;
  }
  &__label {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
  }
  &__type {
    width: 200px;
  }
  &__inputs {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    @include for-size(phone-portrait-down) {
      margin-top: 10px;
    }
  }
  &__select {
    width: 100%;
    max-width: 240px;
    margin-left: 10px;
  }
  .input--invalid &__select {
    border-color: #ff9494;
  }
  &__input {
    max-width: 180px;
  }
  &__separator {
    margin: 0 10px;
  }
}
</style>
