// TODO: удалить во всех используемых местах, использовать DEFAULT_SOURCE_SYSTEM
export const SOURCE_SYSTEM = 'otrs1';

export const TYPES = [
  {
    value: 1,
    label: 'Хост',
    type: 'host',
  },
  {
    value: 2,
    label: 'Сеть',
    type: 'network',
    separator: '/',
  },
  {
    value: 3,
    label: 'Диапазон',
    type: 'range',
    separator: '-',
  },
  {
    value: 4,
    label: 'Интернет',
    type: 'internet',
  },
];
